/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = { 
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var PRC = {};
        PRC.dropDown = function(){
          var target = $(this).attr('data-target');
          console.log('test',target);
          $('.' + target).slideToggle();
        };

        jQuery(document).on('click', 'a[href="#intercom_open"]', function(){
        	Intercom('show');
        });

        jQuery('.drop-activate').click(PRC.dropDown);

        jQuery('.contact-methods__item').hover(function(){
        	$(this).toggleClass('active');
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('[data-toggle="tooltip"]').tooltip();
        



      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // PRC
        // jQuery(document).ready(function(){
        //   jQuery(".owl-carousel").owlCarousel({
        //     nav: true,
        //     loop: true,
        //     items: 3,
        //     navSpeed: 1000,
        //     responsiveClass:true,
        //     responsive:{
        //         0:{
        //             items:1
        //         },
        //         600:{
        //             items:2
        //         },
        //         1000:{
        //             items:3
        //         }
        //     }   
        //   });
        // });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
        console.log(jQuery(".big-number"));

        var inviews = [];
        var counters = [];

        var options = {
          useEasing : true, 
          useGrouping : true, 
          separator : ',', 
          decimal : '.', 
          prefix : '', 
          suffix : '' 
        };

        var index = 0;
        jQuery(".big-number").each(function(){

          my_elm = $(this); 
          my_elm.attr('data-counter-index', index++);
          // console.log(my_elm.html(), parseInt(my_elm.html().replace(",", "") ) );

          counters.push(new CountUp(my_elm[0], 0, parseInt(my_elm.html().replace(",", "") ),  0, 1.5, options ) );

          inviews.push(
            new Waypoint.Inview({
                // offset: 500 ,
              element: my_elm[0],
              enter: function(direction) {
                console.log('Scrolled to waypoint!', jQuery(this.element).attr('data-counter-index') );

                counters[parseInt(jQuery(this.element).attr('data-counter-index'))].start();
                // numAnim.start();

              },
              exited: function(direction) {
                counters[parseInt(jQuery(this.element).attr('data-counter-index'))].reset();

              }
            })
          );
        });

    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
